var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("a-card", {
    staticStyle: {
      margin: "24px 0"
    },
    attrs: {
      bordered: false
    }
  }, [_c("a-table", {
    attrs: {
      pagination: false,
      dataSource: _vm.dataSource,
      columns: _vm.columns
    },
    scopedSlots: _vm._u([{
      key: "action",
      fn: function fn(text, record) {
        return [_c("a", {
          on: {
            click: function click($event) {
              return _vm.showVisible(record);
            }
          }
        }, [_vm._v("修改")]), _c("span", [_c("a-divider", {
          attrs: {
            type: "vertical"
          }
        }), _c("a", {
          on: {
            click: function click($event) {
              return _vm.handleDelete(record.id);
            }
          }
        }, [_vm._v("删除")])], 1)];
      }
    }])
  })], 1)], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };