import { DcListApi } from "@/api";
var columns = [{
  title: "来源编号",
  dataIndex: "id"
}, {
  title: "来源名称",
  dataIndex: "name"
}];
export default {
  data: function data() {
    return {
      columns: columns,
      dataSource: []
    };
  },
  watch: {},
  methods: {
    getList: function getList() {
      var _this = this;
      DcListApi.getsourceList().then(function (res) {
        _this.dataSource = res;
      });
    }
  },
  mounted: function mounted() {
    this.getList();
  }
};